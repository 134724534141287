<template>
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.519 15.74a2.6 2.6 0 0 1 1.568.976c.394.501.592 1.077.592 1.728 0 .939-.331 1.685-.992 2.24-.651.544-1.563.816-2.736.816h-5.232V10.268h5.056c1.14 0 2.032.261 2.672.784.65.523.976 1.232.976 2.128 0 .661-.176 1.21-.528 1.648-.342.437-.8.741-1.376.912Zm-4.064-.928h1.792c.448 0 .789-.096 1.024-.288.245-.203.368-.496.368-.88s-.123-.677-.368-.88c-.235-.203-.576-.304-1.024-.304h-1.792v2.352Zm2.016 4.48c.458 0 .81-.101 1.056-.304.256-.213.384-.517.384-.912s-.134-.704-.4-.928c-.256-.224-.614-.336-1.072-.336h-1.984v2.48h2.016Z"
      fill="currentColor"
      class="text-score-b"
    />
    <path
      d="M31.5 16c0 8.56-6.94 15.5-15.5 15.5C7.44 31.5.5 24.56.5 16 .5 7.44 7.44.5 16 .5 24.56.5 31.5 7.44 31.5 16ZM3.6 16c0 6.848 5.552 12.4 12.4 12.4 6.848 0 12.4-5.552 12.4-12.4 0-6.848-5.552-12.4-12.4-12.4C9.152 3.6 3.6 9.152 3.6 16Z"
      fill="currentColor"
      class="text-gray-300"
    />
    <path
      d="M16 .5A15.5 15.5 0 1 1 1.259 11.21l2.948.958A12.4 12.4 0 1 0 16 3.6V.5Z"
      fill="currentColor"
      class="text-score-b"
    />
  </svg>
</template>
