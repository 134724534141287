<template>
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-score-a">
    <path
      d="M18.086 19.516h-4.192l-.672 1.984h-2.864l4.064-11.232h3.168L21.654 21.5h-2.896l-.672-1.984Zm-.704-2.112-1.392-4.112-1.376 4.112h2.768Z"
      fill="currentColor"
    />
    <path
      d="M31.5 16c0 8.56-6.94 15.5-15.5 15.5C7.44 31.5.5 24.56.5 16 .5 7.44 7.44.5 16 .5 24.56.5 31.5 7.44 31.5 16ZM3.6 16c0 6.848 5.552 12.4 12.4 12.4 6.848 0 12.4-5.552 12.4-12.4 0-6.848-5.552-12.4-12.4-12.4C9.152 3.6 3.6 9.152 3.6 16Z"
      fill="currentColor"
    />
  </svg>
</template>
