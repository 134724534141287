<template>
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4 12.46v2.272h3.664v2.112H15.4v2.464h4.144V21.5h-6.88V10.268h6.88v2.192H15.4Z"
      fill="currentColor"
      class="text-score-e"
    />
    <path
      d="M31.5 16c0 8.56-6.94 15.5-15.5 15.5C7.44 31.5.5 24.56.5 16 .5 7.44 7.44.5 16 .5 24.56.5 31.5 7.44 31.5 16ZM3.6 16c0 6.848 5.552 12.4 12.4 12.4 6.848 0 12.4-5.552 12.4-12.4 0-6.848-5.552-12.4-12.4-12.4C9.152 3.6 3.6 9.152 3.6 16Z"
      fill="currentColor"
      class="text-gray-300"
    />
    <path
      d="M16 .5a15.5 15.5 0 0 1 14.741 10.71l-2.948.958A12.4 12.4 0 0 0 16 3.6V.5Z"
      fill="currentColor"
      class="text-score-e"
    />
  </svg>
</template>
