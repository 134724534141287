<template>
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.434 15.868c0-1.11.24-2.096.72-2.96a5.093 5.093 0 0 1 2-2.032c.864-.49 1.84-.736 2.928-.736 1.334 0 2.475.352 3.424 1.056.95.704 1.584 1.664 1.904 2.88h-3.008a2.414 2.414 0 0 0-.96-1.072 2.635 2.635 0 0 0-1.392-.368c-.842 0-1.525.293-2.048.88-.522.587-.784 1.37-.784 2.352 0 .981.262 1.765.784 2.352.523.587 1.206.88 2.048.88.523 0 .987-.123 1.392-.368.416-.245.736-.603.96-1.072h3.008c-.32 1.216-.954 2.176-1.904 2.88-.95.693-2.09 1.04-3.424 1.04-1.088 0-2.064-.24-2.928-.72a5.232 5.232 0 0 1-2-2.032c-.48-.864-.72-1.85-.72-2.96Z"
      fill="currentColor"
      class="text-score-c"
    />
    <path
      d="M31.5 16c0 8.56-6.94 15.5-15.5 15.5C7.44 31.5.5 24.56.5 16 .5 7.44 7.44.5 16 .5 24.56.5 31.5 7.44 31.5 16ZM3.6 16c0 6.848 5.552 12.4 12.4 12.4 6.848 0 12.4-5.552 12.4-12.4 0-6.848-5.552-12.4-12.4-12.4C9.152 3.6 3.6 9.152 3.6 16Z"
      fill="currentColor"
      class="text-gray-300"
    />
    <path
      d="M16 .5a15.5 15.5 0 1 1-9.11 28.04l1.821-2.508A12.4 12.4 0 1 0 16 3.6V.5Z"
      fill="currentColor"
      class="text-score-c"
    />
  </svg>
</template>
