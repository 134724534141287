<template>
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.5 16c0 8.56-6.94 15.5-15.5 15.5C7.44 31.5.5 24.56.5 16 .5 7.44 7.44.5 16 .5 24.56.5 31.5 7.44 31.5 16ZM3.6 16c0 6.848 5.552 12.4 12.4 12.4 6.848 0 12.4-5.552 12.4-12.4 0-6.848-5.552-12.4-12.4-12.4C9.152 3.6 3.6 9.152 3.6 16Z"
      fill="currentColor"
      class="text-gray-300"
    />
    <path
      d="M16 .5a15.5 15.5 0 0 1 9.11 28.04l-1.822-2.508A12.4 12.4 0 0 0 16 3.6V.5Z"
      fill="currentColor"
      class="text-score-d"
    />
    <path
      d="M15.387 10.268c1.184 0 2.22.235 3.104.704a4.963 4.963 0 0 1 2.049 1.984c.49.843.736 1.819.736 2.928 0 1.099-.246 2.075-.736 2.928a4.94 4.94 0 0 1-2.064 1.984c-.886.47-1.915.704-3.088.704H11.18V10.268h4.207Zm-.176 8.864c1.035 0 1.84-.283 2.417-.848.575-.565.863-1.365.863-2.4s-.288-1.84-.863-2.416c-.576-.576-1.382-.864-2.417-.864h-1.296v6.528h1.296Z"
      fill="currentColor"
      class="text-score-d"
    />
  </svg>
</template>
